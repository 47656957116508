.faq{
    padding: 80px 0;
    background: url(/public/img/wienerdog/bg.png) no-repeat 50% 50% / cover;
    position: relative;
    overflow: hidden;
}

.faq .lottie-rocket {
    position: absolute;
    right: 0;
}

.heading {
    font-size: 48px;
    font-weight: 350;
    font-family: Sausages, sans-serif;
    color: #fff!important;
}

.faq .panel .card .card-header {
    background: #663AA0;
    border-radius: 16px;
    border-bottom: 0;
    padding: 0;
}

.faq .panel {
    max-width: 100%;
    background: #663AA0;
    margin-bottom: 50px;
    border-radius: 16px;
}

.faq .panel .card {
    border-radius: 16px;
    background-color: transparent;
    border: none;
    margin-bottom: 0;
}

.font-md-26 {
    font-size: 26px !important;
}

.p-3 {
    padding: 1rem !important;
}

.faq .lottie {
    width: 321px;
    height: 321px;
    position: absolute;
    bottom: 0;
    transform: rotate(180deg);
}