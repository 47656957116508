.buy-wrapper {
    background: url(../../../public/img/wienerdog/buy-bg.6b5ea7ad58172429.svg) no-repeat center / cover;
    padding: 100px 0;
}

.buy-wrapper .title {
    font-size: 50px;
    font-weight: 800;
    text-align: center;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.flex-md-row {
    flex-direction: row !important;
}

.gap-3 {
    gap: 1rem !important;
}

.buy-wrapper .box {
    padding: 16px;
    border-radius: 16px;
    background: rgba(255, 255, 255, .2);
    max-width: 295px;
    text-align: center;
    height: 100%;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    min-height: 346px;
    width: 295px;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.buy-wrapper .box .desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 180%;
    letter-spacing: .8px;
    color: #fff;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.buy-wrapper .box .head {
    width: 48px;
    height: 48px;
    background: #BA8BF9;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 3px;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buy-wrapper .box .box-title {
    font-size: 23px;
    font-weight: 400;
    line-height: 31px;
    color: #fff;
}

.roadmap-buy-container{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Adjust the gap as needed */
    justify-content: center;
    align-items: center;
}