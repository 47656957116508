

.vertical-carousel-container {
    position: relative;
    margin-top: 140px;
}

.vertical-carousel {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 24px;
    height: 300px;
}

.path01 {
    position: absolute;
    left: 0;
    top: -100px;
    z-index: 4;
}

.path02 {
    position: absolute;
    left: 35px;
    top: -150px;
}

.path03 {
    position: absolute;
    right: -35px;
    top: -35px;
}

.vertical-carousel-container .vertical-carousel .image {
    flex: 0 0 40%;
    max-width: 40%;
    padding-left: 15px;
    margin-bottom: -150px;
}

.vertical-carousel-container .vertical-carousel .image img {
    display: block;
    width: 100%;
}

/* .carousel-area {
    position: relative;
    flex: 0 0 58%;
    max-width: 58%;
    padding: 40px;
} */

.label-content {
    font-size: 28px;
    line-height: 36px;
    font-family: Titan One, sans-serif !important;
}

.text-content {
    font-size: 16px;
    line-height: 24px;
    color: #494a4c;
}

.image-container {
    width: 335px; /* Desired width */
    height: 335px; /* Desired height */
    border-radius: 24px;
    overflow: hidden; /* Ensure image doesn't overflow container */
}

.image-container img {
    width: 100%; /* Ensure the image fills the container horizontally */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove any extra spacing */
}
